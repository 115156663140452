import React from "react"
import { useTranslation } from "react-i18next"

const Navbar = ({ isScrolled, menu }) => {
  const { t } = useTranslation()
  return (
    <nav
      className={`fixed h-16 w-full top-0 left-0 px-2 lg:px-10 xl:px-20 flex justify-center lg:justify-between	items-center flex-nowrap z-50 transition ease-in-out duration-500 ${
        isScrolled && `shadow-md bg-theme-bg-primary`
      }`}
    >
      <p className="text-5xl font-headline">{t("NAVBAR_APP_NAME")}</p>
      {menu}
    </nav>
  )
}

export default Navbar
