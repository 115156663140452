// import React from "react"
// import PropTypes from "prop-types"
// import styled, { css } from "styled-components"
// import { Link } from "gatsby"

// const RoundedLinkButton = ({
//   label,
//   className,
//   url,
//   size,
//   onClick,
//   secondary,
//   disabled,
// }) => {
//   return (
//     <StyledLink
//       className={className}
//       to={url}
//       size={size}
//       onClick={onClick}
//       secondary={secondary}
//       disabled={disabled}
//     >
//       {label}
//     </StyledLink>
//   )
// }

// export default RoundedLinkButton

// const StyledLink = styled(Link)`
//   display: inline-flex;
//   justify-content: center;
//   align-items: center;
//   padding: 0.75em 3em;
//   border-radius: 30px;
//   border: 2px solid
//     ${props =>
//       props.secondary
//         ? props.theme.button.secondary.border
//         : props.theme.button.primary.border};
//   font-family: "Montserrat-ExtraBold";
//   text-transform: uppercase;
//   letter-spacing: 0.1em;
//   color: ${props =>
//     props.secondary
//       ? props.theme.button.secondary.text
//       : props.theme.button.primary.text};
//   background-color: ${props =>
//     props.secondary
//       ? props.theme.button.secondary.background
//       : props.theme.button.primary.background};
//   cursor: pointer;
//   transition: all 150ms linear;
//   @media (hover: hover) {
//     &:hover {
//       background-color: ${props =>
//         props.secondary
//           ? props.theme.button.secondary.hover.background
//           : props.theme.button.primary.hover.background};
//       color: ${props =>
//         props.secondary
//           ? props.theme.button.secondary.hover.text
//           : props.theme.button.primary.hover.text};
//       border-color: ${props =>
//         props.secondary
//           ? props.theme.button.secondary.hover.border
//           : props.theme.button.primary.hover.border};
//     }
//   }

//   ${props =>
//     props.size === "small" &&
//     css`
//       min-width: 0;
//       font-family: "Montserrat-Bold";
//       border-width: 1px;
//       padding: 0.5em 0.75em;
//       letter-spacing: 0;
//       text-transform: none;
//     `}

//   ${props =>
//     props.size === "medium" &&
//     css`
//       min-width: 200px;
//     `}

//   ${props =>
//     props.size === "large" &&
//     css`
//       min-width: 100%;
//     `}

//     ${props =>
//     props.disabled &&
//     css`
//       pointer-events: none;
//       opacity: 0.3;
//     `}
// `

// RoundedLinkButton.propTypes = {
//   label: PropTypes.string.isRequired,
//   className: PropTypes.string,
//   url: PropTypes.string.isRequired,
//   size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
//   onClick: PropTypes.func,
//   secondary: PropTypes.bool,
//   disabled: PropTypes.bool,
// }

// RoundedLinkButton.defaultProps = {
//   label: "click me !",
//   className: null,
//   size: "medium",
//   url: "#",
//   onClick: undefined,
//   secondary: false,
//   disabled: false,
// }

//TODO: fix stories & delete comment

import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { Link } from "gatsby"

const RoundedLinkButton = ({
  label = "click me !",
  onPress,
  url = "#",
  disabled,
  size = "medium",
  variant = "primary",
}) => {
  return (
    <Link
      name={label}
      to={url}
      onClick={onPress}
      disabled={disabled}
      size={size}
      variant={variant}
      className={classnames(
        `flex justify-center items-center rounded-3xl uppercase cursor-pointer tracking-wider	font-headline border-solid border-2 transition-all ease-linear`,
        {
          "font-bolded tracking-normal py-2 px-3 lg:py-1 lg:px-2 normal-case border":
            size === "small",
          "py-3 px-4 w-64": size === "medium",
          "w-full py-3 px-4 ": size === "large",
          "pointer-events-none opacity-30": disabled,
          "border-theme-btn-primary bg-theme-btn-primary text-theme-btn-text-primary lg:hover:bg-theme-btn-secondary lg:hover:text-theme-btn-text-secondary":
            variant === "primary",
          "border-theme-btn-primary bg-theme-btn-secondary text-theme-btn-text-secondary lg:hover:bg-theme-btn-primary lg:hover:text-theme-btn-text-primary":
            variant === "secondary",
          "border-theme-btn-primary bg-transparent text-theme-btn-text-secondary lg:hover:bg-theme-btn-primary lg:hover:text-theme-btn-text-primary":
            variant === "secondary transparent",
        }
      )}
    >
      {label}
    </Link>
  )
}

export default RoundedLinkButton

RoundedLinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  variant: PropTypes.oneOf(["primary", "secondary", "secondary transparent"])
    .isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
  disabled: PropTypes.bool,
}
