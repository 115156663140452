import React from "react"
import { useMediaQuery } from "../../hooks/"

const Footer = () => {
  const isMobile = useMediaQuery("screen and (max-width: 768px)")
  return (
    <div className="flex flex-col py-2 justify-center items-center text-center bg-gray-50 tracking-widest leading-relaxed">
      {isMobile && (
        <div className="pr-20 sm:pr-0">
          <p>Made with ❤️ by inteek.io</p>
          <p>Copyright 2021</p>
        </div>
      )}

      {!isMobile && (
        <>
          <p>Made with ❤️ by inteek.io - Copyright 2021</p>
        </>
      )}
    </div>
  )
}

export default Footer
