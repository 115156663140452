import React from "react"
import SEO from "../../utils/SEO"
import Footer from "../sections/Footer"

const devMode = process.env.NODE_ENV === "development"

//TODO:clean comments

const Layout = ({ title, description, lang, children }) => {
  return (
    // <div id="site-container " class="h-full flex flex-col">
    <div
      id="site-container "
      className={`${devMode && "debug-screens"} h-full flex flex-col`}
    >
      <SEO title={title} description={description} lang={lang} />
      {/* <div
        className={`${
          devMode && "debug-screens"
        }  mx-4 md:mx-10 xl:mx-20 flex-grow`}
      > */}
      {children}
      {/* <div className={`${devMode && "debug-screens"}`}>{children}</div> */}
      <Footer />
    </div>
  )
}

export default Layout
